import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import ContentBlock from 'src/components/contentBlock';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "zstack"
    }}>{`ZStack`}</h1>
    <p>{`Determines order of overlapping views, with proceeding items being placed on top by default.
You can also use `}<inlineCode parentName="p">{`.zIndex()`}</inlineCode>{` to specify your own order. `}</p>
    <p>{`Can also be `}<a parentName="p" {...{
        "href": "https://developer.apple.com/documentation/swiftui/alignment"
      }}>{`aligned`}</a>{` horizontally and vertically. `}</p>
    <ContentBlock imgName="Stacks-ZStack.png" mdxType="ContentBlock">
  <code className="clike">
    {`
/* 
    @frozen struct ZStack<Content> where Content : View
    init(alignment: Alignment = .center, @ViewBuilder content: () -> Content)
*/
VStack {
    ZStack {
        Text("Red")
            .frame(width: 200, height: 200)
            .background(Color.red)
        Text("Blue")
            .frame(width: 100, height: 100)
            .background(Color.blue)
        Text("Yellow")
            .frame(width: 50, height: 50)
            .background(Color.yellow)
    }\n
    Spacer()\n
    ZStack(alignment: .topLeading) {
        Text("Red")
            .frame(width: 200, height: 200)
            .background(Color.red)
        Text("Blue")
            .frame(width: 100, height: 100)
            .background(Color.blue)
        Text("Yellow")
            .frame(width: 50, height: 50)
            .background(Color.yellow)
    }\n
    Spacer()\n
    ZStack {
        Text("Red")
            .frame(width: 200, height: 200)
            .background(Color.red)
            .zIndex(0)
        Text("Blue")
            .frame(width: 100, height: 100)
            .background(Color.blue)
            .zIndex(2)
        Text("Yellow")
            .frame(width: 50, height: 50)
            .background(Color.yellow)
            .zIndex(1)
            .offset(x: 50, y: 50)
    }
}
    `}
  </code>
    </ContentBlock>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      